@import 'prismjs/themes/prism-coy.css';
// @import '@fullcalendar/core/main.css';
// @import '@fullcalendar/daygrid/main.css';
// @import '@fullcalendar/timegrid/main.css';

// @import './assets/flags/flags.css';

$primaryColor:#dc6d46;
$secondaryColor: #309fcf;
$dividerColor:#dbdbdb;
$darkColor: #434343;
$outcomeColor: #6aa84fff;
$hoverBgColor:#e8e8e8;
$transitionDuration:.2s;

pre[class*="language-"] {
    &:before, &:after {
        display: none !important;
    }

    code {
        border-left: 10px solid var(--surface-d) !important;
        box-shadow: none !important;
        background: var(--surface-b) !important;
        margin: 1em 0;
        color: var(--text-color);
        font-size: 14px;

        .token {
            &.tag,
            &.keyword {
                color: #2196F3 !important;
            }

            &.attr-name,
            &.attr-string {
                color: #2196F3 !important;
            }

            &.attr-value {
                color: #4CAF50 !important;
            }

            &.punctuation {
                color: var(--text-color);
            }

            &.operator,
            &.string {
                background: transparent;
            }
        }
    }
}

.docs i {
    font-family: monaco,Consolas,Lucida Console,monospace;
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    padding: 2px;
}

.docs li {
    line-height: 1.5;
}

.layout-topbar {
    .notifications {
        .layout-topbar-action-panel {
            img {
                width: 32px;
                height: 32px;
            }

            .layout-topbar-action-item {
                border-bottom: 1px solid var(--divider-color);

                &:last-child {
                    border-bottom: 0 none;
                }
            }
        }
    }

    .app {
        .layout-topbar-action-panel {
            button {
                padding: .5rem 0;
                margin: auto;

                i {
                    width: 42px;
                    height: 42px;
                    border: 1px solid transparent;
                    border-radius: 50%;
                    margin: .5rem 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
}

.layout-rightmenu {
    .header {
        padding-bottom: .5rem;
        margin-bottom: .714rem;
        border-bottom: 1px solid var(--divider-color);
    }

    .online-members {
        img {
            width: 32px;
        }

        b {
            color: var(--primary-color)
        }
    }

    .latest-activity {
        i {
            border: 1px solid transparent;
            border-radius: 50px;
            background-color: var(--surface-d);
        }
    }

    .next-events {
        ul {
            margin: 0;
            list-style-type: none;
            padding: 0;

            > li {
                padding: .875rem .5rem;
            }
        }
    }
}

.layout-menu-slim.layout-wrapper .layout-main

.layout-menu-slim {
    &.layout-wrapper{
        .layout-main{
            margin-left: 0 !important;
        }
    }
    margin-left: 0;
    .layout-menu-button{
        display: none !important;
    }
}

@media (min-width: 992px) {
    .layout-menu-slim {
        &.layout-wrapper{
            .layout-main{
                margin-left: 0 !important;
            }
        }
        margin-left: 0;
        .layout-menu-button{
            display: none !important;
        }
        .layout-inline-menu {
            .layout-inline-menu-action {
                justify-content: center;

                > span,
                > i {
                    display: none !important;
                }
            }

            .layout-inline-menu-action-panel {
                .layout-inline-menu-action-item {
                    > button {
                        margin: auto;
                        justify-content: center;

                        > i {
                            font-size: 1.5rem;
                            margin-right: 0 !important;
                        }

                        > span {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.p-component{
    &.p-selectbutton{
        &.p-buttonset{
            .p-button{
                border: 1px solid $primaryColor;
                color: $primaryColor;
                &:not(:last-child) {
                    border-right: 0 none;
                }
                &:hover{
                    border: 1px solid $primaryColor;
                    color: white;
                    background-color: rgba($primaryColor, .85);
                }
                &.p-highlight{
                    background-color: $primaryColor;
                    color: white;
                }
            }
        }
    }
}

.card{
    &.draggable{
        .card-header{
            cursor: grab;
            label{
                cursor: grab;
            }
        }
        .isDragging{
            cursor: grabbing;
            border-style: dotted;
        }
    }
    header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 600;
        
        .action {
            cursor: pointer;
        }
    }
}

.layout-content{
    display: flex;
}

.inbox{
    .table-header{
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media(max-width: 768px) {
            flex-direction: column;
        }
        h3{
            margin: 0;
        }
    }
}

.pop{
    display: flex;
    flex-direction: column;
    flex: 1 1 0;

    .play-selector-container{
        margin-top: 1rem;
        .p-tag{
            margin-left: 0.5rem;
            cursor: pointer;
        }
    }

    .p-toolbar{
        .p-button-outlined{
            &.active{
                border: 1px solid $primaryColor;
                background-color: $primaryColor;
                color: white;
            }
        }
    }

    .pop-motion{
        position: relative;
        display: flex;
        margin: 0px;
        flex: 1 1 0%;
        .pop-motion-item{
            padding: 0px;
            .pop-motion-item-header{
                display: flex;
                justify-content: space-between;
                padding: 10px;
                height: 50px;
                align-items: center;
                font-size: 16px;
                border: 1px solid white;
                background-color: rgba($primaryColor, .9);
                color: white;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-weight: 600;
                .title{
                    .summary{
                        font-size: 12px;
                        font-weight: 100;
                    }
                }
                .conf{
                    cursor: pointer;
                }
            }
            &.drag-in{
                .pop-motion-item-header{
                    background-color: rgba($primaryColor, 1);
                }
                background-color: $dividerColor;
            }
        }
    }

    .card{
        margin: 5px;
        padding: 10px;
        &.dragging{
            background-color: rgba($primaryColor, 0.3);
        }
        .title{
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-weight: 600;
            .config{
                cursor: pointer;
            }
        }
        .content{
            margin-top: 5px;
            ul{
                list-style-type:none;
                margin: 0px;
                padding: 0px;
                li{
                    padding: 2px;
                }
            }
        }
    }

    .pop-hitlist{
        flex: 1 1 0;
        #hitlist-opportunities{
            display: flex;
            flex-direction: column;
            .list-header{
                padding: 0;
                padding-top: 1.5rem;
                padding-bottom: .5rem;
            }
            .list{
                overflow-y: scroll;
                flex: 1 0 1px;
                padding: 0;
            }
        }
    }

}

.motion{
    height: 800px;
    .p-col{
        height: 100%;
        .column{
            height: 100%;
            overflow: scroll;
        }
    }
}
.playbook-container{
    width: 100%;
    .playbook{
        display: flex;
        flex-direction: column;
        flex: 1 1 0;

        .p-menubar .p-menuitem.active .p-menuitem-link{
            background-color: rgba($primaryColor, .1);
        }

        .playbook-title-input{
            width: 100%;
            font-size: 1.5rem;
            font-weight: bold;
            border-bottom: 0px;
            background-image: none;
            &.p-disabled:disabled{
                opacity: 1;
            }
        }

        .tools-menu{
            background-color: white;
        }
        .playbook-table{
            width: 100%;
            border-width: 0px;
            margin: 0px;
            border-spacing: 0px;
            margin-top: 1rem;
            .editable-cell{
                position: relative;
                input{
                    min-width: auto;
                    margin: 0px;
                    border: 0px;
                    border-radius: 0px;
                    background-image: none;
                    display: block;
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                }
                .conf{
                    position: absolute;
                    right: 5px;
                    top: 10px;
                }
            }
            .role{
                width: 80px;

            }
            th{
                margin: 0px;
                height: 50px;
                min-width: 150px;
                border: 1px solid white;
                white-space: nowrap;
                text-overflow: ellipsis;
                justify-content: space-between;
                align-items: center;
                background-color: rgba($primaryColor, 0.9);
                cursor: pointer;
                color: white;
                font-size: 16px;
                &.none-editable{
                    background-color: rgba($darkColor, 0.9);
                    cursor: auto;
                    &:hover{
                        background-color: rgba($darkColor, 0.9);
                    }
                }
                input{
                    font-size: 16px;
                }
                &:hover{
                    background-color: rgba($primaryColor, 1);
                }
                .header{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    label{
                        margin: 10px;
                        width: 100%;
                        text-align: left;
                        .summary{
                            font-size: 12px;
                            font-weight: 100;
                        }
                    }
                    .menu{
                        button{color: white;}
                    }
                }
                &.add-action{
                    min-width: 50px;
                    cursor: pointer;
                    color: rgba($primaryColor, 0.9);
                    background-color: #f7f6f7;
                    border: 0px;
                    &:hover{
                        color: $primaryColor;
                    }
                }
            }
            tbody{
                tr{
                    height: 80px;
                    &.add{
                        height: auto;
                        background-color: #f7f6f7;
                        color: lightgray;
                        text-align: center;
                        td{
                            padding: 5px;
                            border: 0px solid #FFF;
                            &.add-action{
                                background-color: #FFF;
                                color: rgba($secondaryColor, 1);
                                cursor: pointer;
                                &:hover{
                                    color: $secondaryColor;
                                }
                            }
                        }
                    }
                }
                .role{
                    text-align: center;
                    border: 1px solid #FFF;
                    background-color: rgba($secondaryColor, 0.9);
                    cursor: pointer;
                    &:hover{
                        background-color: rgba($secondaryColor, 1);
                    }
                    input{
                        text-align: center;
                    }
                }
                .stage{
                    text-align: left;
                    vertical-align: top;
                    border: 3px solid #FFF;
                    background-color: #dddddd;
                    &.drag-in{
                        background-color: #b5b5b5;
                    }
                    .new-action{
                        text-align: center;
                        color: $darkColor;
                        cursor: pointer;
                        .p-inplace{
                            display: block;
                            .p-inplace-display{
                                margin: 5px;
                                display: block;
                            }
                        }
                    }
                }
                .outcome-cell{
                    border: 3px solid #FFF;
                    background-color: #dddddd;
                    &.header{
                        font-weight: 600;
                        vertical-align: middle;
                        padding: 10px;
                        background-color: rgba($darkColor, .9);
                        color: white;
                        border: 1px solid #FFF;
                    }
                    vertical-align: top;
                    &.empty{
                        vertical-align: middle;
                    }
                    .item{
                        padding: 0.3rem;
                        margin-bottom: 0px;
                        margin: 5px;
                        &.editing{
                            padding: 0px;
                            header{ 
                                color: #515C66; font-weight: 100; 
                                label{ 
                                    height: 100%;
                                    .p-inputtext{
                                        height: 100%;
                                        padding: 0.3rem ;
                                    }
                                } 
                            }
                        }
                        .card-header{
                            height: 100%;
                            .action{
                                margin-left: 10px;
                            }
                        }
                        // -webkit-clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
                        // clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
                        // border: 0px solid $primaryColor;
                        // background-color: $dividerColor;
    
                        // padding: 0.3rem;
                        
                        // box-shadow: 0 0 0 0 transparent;
                        // margin-bottom: 0px;
                        // margin: 5px;
                        // display: flex;
                        // align-items: center;
                        // justify-content: space-between;
                        // font-weight: normal;
                        // cursor: pointer;
                        // .close{
                        //     padding-right: 4px;
                        //     cursor: pointer;
                        // }
                    }
                    .new-outcome{
                        text-align: center;
                        color: rgba($darkColor, .9);
                        cursor: pointer;
                        &:hover{
                            color: $darkColor;
                        }
                    }
                }
            }
        }
    }

    .card{
        &.action, &.outcome{
            margin: 5px;
            padding: 5px;
            &.dragging{
                background-color: rgba($primaryColor, 0.3);
            }
            .title{
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: 500;
                .config{
                    cursor: pointer;
                }
            }
            .content{
                margin-top: 5px;
                ul{
                    list-style-type:none;
                    margin: 0px;
                    padding: 0px;
                    li{
                        padding: 2px;
                    }
                }
            }
            &.editing{
                .title{
                    margin: -5px;
                    label{ width: 100%; }
                    color: #515C66;
                    font-weight: 400;
                    .p-inputtext{
                        width: 100%;
                        height: 100%;
                        padding-left: 5px;
                    }
                }
            }
            .link{
                color: $secondaryColor;
                text-decoration: underline;
            }
        }
    }

    // .card{
    //     &.action, &.outcome{
    //         padding: 0.3rem;
    //         margin-bottom: 0px;
    //         margin: 5px;
    //         &.editing{
    //             padding: 0px;
    //             header{ 
    //                 color: #515C66; font-weight: 100; 
    //                 label{ 
    //                     height: 100%;
    //                     .p-inputtext{
    //                         height: 100%;
    //                         padding: 0.3rem ;
    //                     }
    //                 } 
    //             }
    //         }
    //         .card-header{
    //             height: 100%;
    //             .action{
    //                 margin-left: 10px;
    //             }
    //         }
    //     }
    // }

    &.sequence-mode{
        .not-in-sequence{
            display: none;
        }
        &.edit-mode{
            .not-in-sequence{
                display: block;
            }
            .in-sequence{
                border: 2px solid #dc6d46;
            }
        }    
    }
}


.outcomes-editor{
    .outcome-markers{
        .p-timeline-event{
            display: block;
            min-height: 35px;
            .p-timeline-event-opposite{
                padding: 0px;
            }
            .p-timeline-event-content{
                padding: 0px;
            }
            .outcome-marker{
                cursor: pointer;
                font-size: 18px;
                &.required{
                    color: red;
                }
                &.completed{
                    color: $outcomeColor;
                }
                &.active{
                    font-weight: bold;
                }
            }
        }
    }
}

.outcome-form{
    .p-button{
        &:focus, &:hover{
            border: 1px solid $primaryColor;
            background-color: rgba($primaryColor, .75);
            color: $primaryColor;
        }
    }
    .p-button-outlined{
        
        &.active{
            border: 1px solid $primaryColor;
            background-color: $primaryColor;
            color: white;
            &:hover{
                border: 1px solid $primaryColor;
                background-color: $primaryColor;
                color: white;
            }
            &:focus{
                border: 1px solid $primaryColor;
                background-color: $primaryColor;
                color: white;
            }
        }
    }
}

.dashboard{
    .widget-alerts {
        .layout-topbar-action-panel {
            list-style: none;
            padding: 0;
            margin: 0;
            
            .layout-topbar-action-item {
                border-bottom: 1px solid var(--divider-color);
                cursor: pointer;
                padding: 1rem;
                &:hover {
                    background-color: $hoverBgColor;
                    transition: background-color $transitionDuration;
                }

                &:last-child {
                    border-bottom: 0 none;
                }
            }
        }
    }
}

.grid.with-sidebar{
    .p-sidebar-mask{
        flex: 0 0 auto;
        width: 20%;
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        margin-right: -1.5rem;
        margin-left: 5px;
        
        .p-sidebar{
            width: auto;
            box-shadow: none;

            -webkit-transition: all 0s;
            -moz-transition: all 0s;
            -o-transition: all 0s;
            transition: all 0s;

            .p-sidebar-content{
                .sidebar-options{
                    list-style: none;
                    margin-block-start: 0px;
                    margin-block-end: 0px;
                    margin-inline-start: 0px;
                    margin-inline-end: 0px;
                    padding-inline-start: 0px;
                    .sidebar-options-item{
                        cursor: pointer;
                        border-bottom: 1px solid var(--divider-color);
                        padding: 1rem;
                        &:hover {
                            background-color: $hoverBgColor;
                            transition: background-color $transitionDuration;
                        }
                        &.form{
                            cursor: auto;
                            padding: 0;
                            &:hover{
                                background-color: transparent;
                            }

                            .p-accordion{
                                .p-accordion-tab{
                                    margin: 0px;
                                    box-shadow: none;
                                    .p-accordion-header{
                                        a{
                                            padding: 0.5rem;
                                        }
                                    }
                                }
                            }

                            .action-picker{
                                list-style: none;
                                padding: 0px;
                                margin: 0px;

                                li{
                                    margin-bottom: 0.25rem;
                                    &.stage{
                                        font-weight: bold;
                                        color: $primaryColor;
                                    }
                                }
                            }
                        }
                    }
                    .sidebar-options-not-found{
                        padding: 1rem;
                        text-align: center;
                        color: $darkColor;
                    }
                }
            }

        }
    }
}
